export const validadInput = (inputs) => {
  // console.log(inputs);
  return new Promise((resolve, reject) => {
    const objeto = {};
    // const arrayDates = [];
    const resp = inputs.map((input) => {
      return {
        [input.key]: input.required,
      };
    });

    for (const item of resp) {
      // console.log(item);
      Object.assign(objeto, item);
    }
    // console.log(objeto);
    resolve(objeto);
  });
};
